<template>
  <div class="ApiWeb" style="height:50vh;">
    <HeaderVisor />
    <div>
      <ApiWeb />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderVisor from "@/components/HeaderVisor.vue";
import ApiWeb from "@/components/ApiWeb.vue";

export default {
  name: "home",
  components: {
    HeaderVisor,
    ApiWeb,
  }
};
</script>